import { SharedAngular } from '@Angular.Runner/@types/sharedAngular';
import { IHttpService, IQService } from 'angular';
import { IStateParamsService } from 'angular-ui-router';

/**
 * @ngdoc directive
 * @name flowTableFileUpload
 * @module flowingly.components
 * @description  This comppnent is responsbile for uploading a file for a table cell.
 * ### Notes
 * The use case here is slightly different so we cannot resuse the shared version of this directive.
 * ### Properties
 * #### Inputs
 * * stepId : ID of the step to upload the file against
 * * flowId : ID of the flow to upload the file against
 * * fieldName : name of the table
 * * cell : the cell the file is uploaded for
 * * rowId : the id of the row the cell is on
 * #### Outputs
 * * fileId : id of the uploaded file (returned from server)
 * #### Events
 * * onFileUpload : method to call when file upload completed
 * @usage
 * ```
   <flow-table-file-upload file-id="cell.value" cell="cell" row-id="row.id" step-id="$ctrl.stepId" on-file-upload="$ctrl.updateForm(cell)"></flow-table-file-upload>
 * ```
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/0167263d57cc4f14858ca4b517cf2122df9218b3/src/Flowingly.Shared.Angular/flowingly.components/tablerunner/table.runner.fileUpload.js?at=master
 */
(function () {
  'use strict';

  angular.module('flowingly.components').component('flowTableFileUpload', {
    bindings: {
      stepId: '<',
      flowModelId: '<',
      flowId: '<',
      fieldName: '<',
      cell: '<',
      rowId: '<',
      fileId: '=',
      onFileUpload: '&'
    },
    templateUrl: 'table.runner.fileUpload.tmpl.html',
    controller: [
      '$http',
      '$stateParams',
      'pubsubService',
      'sessionService',
      'fileService',
      'notificationService',
      'tokenService',
      'APP_CONFIG',
      '$q',
      function (
        $http: IHttpService,
        $stateParams: IStateParamsService,
        pubsubService: SharedAngular.PubSubService,
        sessionService: SharedAngular.SessionService,
        fileService: SharedAngular.FileService,
        notificationService: SharedAngular.NotificationService,
        tokenService: SharedAngular.TokenService
      ) {
        var $ctrl = this;
        $ctrl.onFileChange = onFileChange;
        $ctrl.removeFile = removeFile;

        this.$onInit = function () {
          $ctrl.uploadedFile = undefined;

          if (!$ctrl.fileId) {
            return;
          }

          let filePromise;

          if ($stateParams.isExternalForm) {
            filePromise = fileService
              .getFileAnonymous($ctrl.fileId, $ctrl.stepId)
              .then((response) => response);
          } else {
            const user = sessionService.getUser();
            if (!user) {
              return;
            }
            fileService.setUser(user.id, tokenService.getTenant().id);
            filePromise = getFile($ctrl.fileId).then(
              (response) => response.data
            );
          }

          filePromise.then((fileData) => {
            $ctrl.uploadedFile = {
              filename: fileData.filename,
              id: $ctrl.fileId,
              key: $ctrl.fieldName,
              downloadLink: $stateParams.isExternalForm
                ? fileService.getDownloadLink($ctrl.fileId, true)
                : createDownloadLink($ctrl.fileId),
              size: fileData.size
            };
          });
        };

        function getFile(fileId) {
          return fileService.getFileById(fileId);
        }

        function onFileChange(event) {
          pubsubService.publish('FILEUPLOAD_UPLOAD_STARTED');
          //handle file change notification (from onFileChange)
          event.stopPropagation();
          var fileToUpload = event.target.files[0];
          uploadFile(fileToUpload);
        }

        function removeFile() {
          pubsubService.publish('FILEUPLOAD_REMOVAL_STARTED');

          var deleteService;
          if (sessionService.getUser()) {
            deleteService = fileService.deleteFile($ctrl.fileId, $ctrl.stepId);
          } else {
            deleteService = fileService.deleteAnonymousFile(
              $ctrl.fileId,
              $ctrl.stepId
            );
          }
          deleteService.then(function () {
            $ctrl.uploadedFile = undefined;
            $ctrl.cell.value = undefined;
            $ctrl.onFileUpload({ cell: $ctrl.cell });
            pubsubService.publish('FILEUPLOAD_REMOVAL_COMPLETED');
          });
        }

        /// PRIVATE METHODS //////////////////////////////////////////////////////////

        function uploadFile(fileToUpload) {
          var uploadService;
          if ($ctrl.stepId == undefined || $ctrl.stepId == '') {
            //stepId will be undefined if we are uploading a file for a flow not yet started
            $ctrl.stepId = '00000000-0000-0000-0000-000000000000';
          }
          if (sessionService.getUser()) {
            uploadService = fileService.uploadTableFile(
              `/${$ctrl.stepId}/${$ctrl.cell.id}/${$ctrl.rowId}`,
              fileToUpload
            );
          } else {
            uploadService = fileService.uploadTableAnonymousFile(
              `form/${$ctrl.flowModelId}/step/${$ctrl.stepId}/field/${$ctrl.fieldName}/${$ctrl.rowId}/${$ctrl.cell.id}/file`,
              fileToUpload
            );
          }

          //we need to store the row and cell id

          uploadService.then(function (response) {
            $ctrl.fileId = response.data;
            $ctrl.cell.value = response.data;
            var size = Math.floor(fileToUpload.size / 1000);
            $ctrl.uploadedFile = {
              //stepId is used to keep track of files associated to this step
              filename: fileToUpload.name,
              id: response.data,
              key: $ctrl.fieldName,
              downloadLink: createDownloadLink(response.data),
              size: size > 0 ? size : 1
            };
            $ctrl.onFileUpload({ cell: $ctrl.cell });
            pubsubService.publish('FILEUPLOAD_UPLOAD_COMPLETED');
            notificationService.showSuccessToast(
              fileToUpload.name + ' uploaded'
            );
          });
        }

        function createDownloadLink(fileId) {
          return fileService.getDownloadLink(
            fileId,
            $stateParams.formType == 'flowingly_public_form'
          );
        }
      }
    ]
  });
})();
